<template>
   <div class="page-internal page-quem-somos">
      <div class="topo-page">
         <b-container class="h-100">  
            <h1 class="title-big">INSIGHT <span>SOLUTION</span></h1>
            <p>Somos empresa de desenvolvimento web, trabalhamos para gerar soluções inteligentes para seu negócio, estamos presentes no mercado desenvolvendo produtos web de forma ágil e completa</p>

            <div class="img-tit-page">
               <u-animate-container>
                  <u-animate
                     name="animate__fadeInUp"
                     delay="0.5s"
                     duration="2s"
                     :iteration="1"
                     :offset="0"
                     animateClass="animated"
                     :begin="false"
                     >
                     <i><img src="@/assets/images/img-topo-quem-somos.png" alt=""></i>
                  </u-animate>
               </u-animate-container>
            </div>
         </b-container>
      </div>

      <section class="section-sobre">
         <b-container class="h-100">  
            <div class="d-flex">

               <div class="info">
                  <h2 class="title-section">CONHEÇA NOSSO DIA-A-DIA <span>DO TRABALHO AO HAPPY</span></h2>

                  <div class="box-info">
                     <p>Nossos dias de trabalho, contam com ótimos profissionais dedicados a entregas de qualidade e comunicação clara entre os diversas áreas.</p>
                     <p>Trabalhamos dia a dia com várias tecnologias e atualizações, Nossas reuniões sobre os produtos e serviços, tornam a comunicação ainda melhor e eficaz o aprendizado sobre negócios.</p>
                     <p>Nossa time conta com uma ótima estrutura de tecnologia para o trabalho e livre comunicação entre os setores, de forma transparente e ágil.</p>
                     <p>Momentos de descontração e diversão ajudam a relaxar e produzir de forma mais eficaz, valorizando nosso time e também suas necessidades.</p>
                  </div>
               </div>

               <div class="img-sobre">
                  <img src="@/assets/images/img-sobre.png" alt="">
               </div>
            </div>
         </b-container>
      </section>

      <BoxFrase></BoxFrase>
   </div>
</template>
<script>
   import BoxFrase from '@/components/BoxFrase/BoxFrase';
   import { UAnimateContainer, UAnimate } from "vue-wow";

   export default {
      name: 'Quem-Somos',
      components: {
         BoxFrase,
         UAnimateContainer,
         UAnimate,
      }
   }
</script>


<style lang="scss">
  @import "Quem-Somos.scss";
</style>
