<template>
   <section class="section-frase">
      <b-container class="h-100">  
         <div class="d-flex">
            <p><strong>Seu crescimento pessoal e profissional</strong> é o nosso crescimento como time e o futuro da nossa empresa</p>
         </div>
      </b-container>
   </section>
</template>

<script>
  export default {
    name: 'BoxFrase',
  }
</script>

<style lang="scss">
  @import "BoxFrase.scss";
</style>
